@use '@angular/material' as mat;
@include mat.elevation-classes();
@include mat.app-background();

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

// Importing tailwin utilities and styles
@tailwind base;
@tailwind components;
@tailwind utilities;

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: white !important
}

$bluesky-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$bluesky-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);
// The warn palette is optional (defaults to red).
$bluesky-warn: mat.m2-define-palette(mat.$m2-red-palette);

$my-typography: mat.m2-define-typography-config($font-family: 'Open Sans',);

$my-theme: mat.m2-define-light-theme((typography: $my-typography, color: (
    primary: $bluesky-primary,
    accent: $bluesky-accent,
    warn: $bluesky-warn,
  )));
@include mat.all-component-themes($my-theme);

// $custom-typography: mat-typography-config(
//   $font-family: 'Open Sans'
// );
// @include mat-core($custom-typography);